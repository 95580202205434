import * as Yup from 'yup'
import React from 'react'
import {atPath} from '@libs/utils'
import {Input, InputNumber} from 'antd'
import {FieldComponentFactory, FieldComponentProps} from '@components/forms/fields/fields.t'
import {useField, useFormikContext} from 'formik'
import _ from 'lodash'
import formatNumber from '@libs/helpers/numbers'
import FormItem from '../form-item/form-item'

interface TextInputComponentProps {
  key: string
  label: string
  name: string
  type: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  min?: number
  max?: number
  step?: number
}

const InputWrapper = (props: {
  name: string
  type: string
  value?: unknown
  disabled?: boolean
  onChange?: (v: string | number | null) => void
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {type, value, onChange, ...filteredProps} = props
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formField, meta] = useField(props.name)

  if (type === 'number')
    return (
      <InputNumber
        {...filteredProps}
        defaultValue={meta.initialValue}
        value={formField.value}
        decimalSeparator=','
        formatter={formatNumber}
        onChange={(value) => onChange?.(value)}
      />
    )
  else if (type === 'password')
    return (
      <Input.Password
        {...filteredProps}
        value={formField.value}
        onChange={(e) => onChange?.(e.target.value)}
      />
    )
  else if (type === 'textarea')
    return (
      <Input.TextArea
        {...filteredProps}
        value={formField.value}
        autoSize={{minRows: 2, maxRows: 20}}
        onChange={(e) => onChange?.(e.target.value)}
      />
    )

  return (
    <Input
      {...filteredProps}
      value={formField.value}
      type={type}
      onChange={(e) => onChange?.(e.target.value)}
    />
  )
}

const TextInputComponent: React.FC<FieldComponentProps<TextInputComponentProps>> = ({field}) => {
  const formProps = useFormikContext()
  const [formikField] = useField<string | number>(field)

  return (
    <FormItem field={field} style={{flex: 1}} className='mb-2'>
      <InputWrapper
        type={field.type}
        name={formikField.name}
        disabled={field.disabled}
        onChange={(v) => formProps.setFieldValue(formikField.name, v)}
      />
    </FormItem>
  )
}

const TextInput: FieldComponentFactory = (field) => {
  return {
    initialValue(data) {
      const value = data && atPath(data, field.key)
      if (data && value !== undefined) {
        if (field.type === 'number') return Number(value)
        return value
      }
      if (field.type === 'number')
        return field.defaultValue ? Number(field.defaultValue) : undefined
      return field.defaultValue || ''
    },
    validationSchema() {
      let schema: Yup.NumberSchema<number | undefined | null> | Yup.StringSchema
      switch (field.type) {
        case 'number':
          schema = Yup.number().nullable()
          break
        case 'email':
          schema = Yup.string().email()
          break
        case 'url':
          schema = Yup.string().url()
          break
        default:
          schema = Yup.string()
      }

      if (field.required) schema = schema.required()

      return {[field.key]: schema}
    },
    generateComponent() {
      return <TextInputComponent field={_.omit(field, 'hidden', 'ref')} />
    }
  }
}

export default TextInput
